
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';
import { PRICE_SHOWN } from '@/modules/rates/constants';
import { OtelRateDayPopupLoading } from '@/modules/open-telemetry/decorators';
import type Day from '@/modules/common/types/day.type';
import type { SettingsGeneralService } from '@/modules/settings/settings-general.service';
import type ClusterDiLiteService from '@/modules/cluster/cluster-di-lite.service';
import type UserService from '@/modules/user/user.service';

import { ColumnKey } from '../constants';
import DayPopup from './day-popup/index.vue';
import type {
    RateTrendsService,
    TrendsPopup,
    ScanProps,
    TableOptions,
    TrendsWithRatesDocument,
} from '../types';

@OtelRateDayPopupLoading(KEY.ClusterAllChannelsTrendsService)
@Component({ components: { DayPopup } })
export default class ClusterAllChannelsDayPopup extends Vue implements TrendsPopup {
    /** This component is only for all channels (di lite) cluster rates page popup */

    @inject(KEY.UserService) private userService!: UserService;
    @inject(KEY.ClusterDiLiteService) private clusterDiLiteService!: ClusterDiLiteService;
    @inject(KEY.ClusterAllChannelsTrendsService) private rateTrendsService!: RateTrendsService & TrendsWithRatesDocument;
    @inject(KEY.SettingsGeneralService) private settingsGeneralService!: SettingsGeneralService;

    mounted() {
        this.rateTrendsService.init(this.day);
    }

    get day() {
        return parseInt(this.$route.params.day, 10) as Day;
    }

    get tableColumns() {
        return {
            [ColumnKey.PRICE_TYPE]: this.rateTrendsService.filters?.priceType === PRICE_TYPE.LOWEST
                ? {
                    label: 'titles.type',
                    width: '4%',
                }
                : undefined,
            [ColumnKey.COLOR]: {
                label: '',
                width: '4%',
            },
            [ColumnKey.NAME]: {
                label: 'titles.name',
                width: '41%',
            },
            [ColumnKey.RANK]: {
                label: 'titles.rank',
                width: '8%',
            },
            [ColumnKey.BASIC]: {
                label: '',
                width: '2%',
            },
            [ColumnKey.PRICE]: {
                label: 'titles.price',
                width: '16%',
            },
            [ColumnKey.DIFF]: {
                label: 'titles.diffper',
                width: '17%',
            },
        };
    }

    get tableOptions() {
        return {
            isLinkShown: true,
            isLOSRestrictionShown: true,
            isNetTotalCalcShown: true,
        } as TableOptions;
    }

    get tooltipColumns() {
        return {
            [ColumnKey.COLOR]: {
                label: '',
                width: '4%',
            },
            [ColumnKey.NAME]: {
                label: 'titles.name',
                width: '47%',
            },
            [ColumnKey.PRICE]: {
                label: 'titles.price',
                width: '13%',
            },
            [ColumnKey.DIFF]: {
                label: 'titles.diffper',
                width: '17%',
            },
        };
    }

    get tooltipOptions() {
        return {
            isLOSRestrictionShown: true,
            maxEntities: 6,
        } as TableOptions;
    }

    get colors() {
        return this.settingsGeneralService.chartColors;
    }

    get compset() {
        return this.rateTrendsService.compset;
    }

    get scanProps() {
        const { compset, ratesDocument } = this.rateTrendsService;

        if (!compset || !ratesDocument?.checkinDates || !ratesDocument.checkinDates[this.day]) {
            return {
                lastScan: null,
                data: null,
                settings: null,
                compsetId: '',
                day: this.day,
                disabled: true,
                showScanDate: true,
            } as ScanProps;
        }

        const lastUpdates = Object.values(ratesDocument.checkinDates[this.day])
            .reduce((acc, providerData) => {
                if (!providerData.updateDate) {
                    return acc;
                }

                return [
                    ...acc,
                    new Date(providerData.updateDate),
                ];
            }, [] as Date[])
            .sort((a: Date, b: Date) => b.getTime() - a.getTime());

        return {
            lastScan: lastUpdates.length ? lastUpdates[0] : null,
            day: this.day,
            data: null,
            settings: this.clusterDiLiteService.settings,
            compsetId: compset.id,
            disabled: false,
            showScanDate: true,
        } as ScanProps;
    }

    get isDocumentLoading() {
        return this.rateTrendsService.ratesLoading.isLoading();
    }

    get isChartLoading() {
        return this.isDocumentLoading || this.rateTrendsService.trendsLoading.isLoading();
    }

    get isSuperadmin() {
        return this.userService.isSuperadmin;
    }

    handleDayChange() {
        this.$nextTick(() => this.rateTrendsService.update(this.day));
    }

    handlePriceShownChange(e: PRICE_SHOWN) {
        this.rateTrendsService.localPriceShown = e;
    }
}
